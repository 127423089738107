/* global window */
import {
  init, setUserId, Identify, logEvent, identify,
} from '@amplitude/analytics-browser';

// from Amplitude docs: API Keys are public. They aren't vulnerable to leaks or compromises.
init('c8657933322b31cd45b805f64859e110', { defaultTracking: false });

export function setAmplitudeUser(userId, companyId, country, ip) {
  setUserId(userId);

  const userIdentify = new Identify();
  userIdentify.set('company_id', companyId);
  userIdentify.set('company_country', country);
  userIdentify.set('ip_address', ip);
  identify(userIdentify);
}

export function trackAmplitudeEvent(eventName, properties = {}) {
  logEvent(eventName, properties);
}

window.setAmplitudeUser = setAmplitudeUser;
window.trackAmplitudeEvent = trackAmplitudeEvent;
